const fadeInUpAnimation = () => ({
    init() {
        this.$nextTick(() => {
            this.handleFadeInUpAnimations();
            window.addEventListener('scroll', () => this.handleFadeInUpAnimations());
            
            let resizeTimer;
            window.addEventListener('resize', () => {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => this.handleFadeInUpAnimations(), 250);
            });
        });
    },
    isElementInViewport(el, offset) {
        const rect = el.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        return (
            (rect.top >= -offset && rect.top <= windowHeight - offset) ||
            (rect.bottom >= offset && rect.bottom <= windowHeight + offset) ||
            (rect.top <= 0 && rect.bottom >= windowHeight)
        );
    },
    handleFadeInUpAnimations() {
        const elements = document.querySelectorAll('.fadeInUp:not(.animated)');
        elements.forEach(element => {
            const offset = parseInt(element.getAttribute('data-animation-offset') || '100', 10);
            if (this.isElementInViewport(element, offset)) {
                const delay = element.getAttribute('data-animation-delay') || '0s';
                const duration = element.getAttribute('data-animation-duration') || '1s';
                
                element.style.animationDelay = delay;
                element.style.animationDuration = duration;
                element.classList.add('animated');
            }
        });
    }
});

export default fadeInUpAnimation;