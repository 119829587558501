import '../css/main.css';
import lazyLoadModule from './utils/loaders/moduleLoader.js';
import riveScrollAnimation from './components/riveScrollAnimation.js';
import fadeInUpAnimation from './components/fadeInUpAnimation.js';
import { initializeVideos } from './components/videoInit.js';

lazyLoadModule('addTableWrapper', 3000).then(() => {});
lazyLoadModule('alpineComponents', 3000).then(() => {
  console.log('alpineComponents has been loaded');
});
lazyLoadModule('lazyLoadImages', 3000).then(() => {
  console.log('lazyLoadImages has been loaded');
});
lazyLoadModule('addScrollToSection', 0).then(() => {});
riveScrollAnimation.init();

document.addEventListener('alpine:init', () => {
  Alpine.data('fadeInUpAnimation', fadeInUpAnimation);
});

document.addEventListener('DOMContentLoaded', initializeVideos);

if (import.meta.hot) {
  // HMR code
  import.meta.hot.accept();
}